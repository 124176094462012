import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{attrs:{"flat":"","multiple":""}},[_vm._l((_vm.sortedCameraVideos),function(camera,index){return _c(VExpansionPanel,{key:camera.camera_id},[_c(VRow,{attrs:{"align":"center","justify":"space-between"}},[_c(VCol,{staticClass:"flex-grow-1",attrs:{"cols":"auto"}},[_c(VExpansionPanelHeader,{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{attrs:{"color":"secondary","small":""}},[_vm._v("mdi-chevron-down")])]},proxy:true}],null,true)},[_c('p',{staticClass:"mb-0 d-flex align-center"},[_c('camera-icon',{attrs:{"number":index + 1,"size":22}}),_c('span',{staticClass:"mx-2 subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(camera.camera_name)+" ")])],1)])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('u-button',{staticClass:"rounded mr-2",attrs:{"color":"gray-7","fab":"","small":"","tile":""},on:{"click":function($event){return _vm.launchAssignPreviewModal(camera)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-pen")])],1),_c('u-button',{staticClass:"rounded mr-2",attrs:{"color":"gray-7","fab":"","small":"","tile":""},on:{"click":function($event){return _vm.launchDeleteConfirmationModal(camera)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-trash")])],1)],1),_c(VContainer,{staticClass:"pb-0 col d-flex justify-space-between status"},[_vm._l((_vm.getDetectionStatusByCamera(camera.camera_id)),function(ref,idx){
var status = ref.status;
var timestamp = ref.timestamp;
return _c('u-status-indicator',{key:idx,attrs:{"camera-index":camera.camera_id,"status":status.toLowerCase(),"timestamp":timestamp ? new Date(timestamp).toDateString() : '--'}})}),(_vm.getLatestStatus(camera.camera_id) === 'processed')?_c('u-button',{attrs:{"color":"primary theme--light secondary--text font-weight-bold btn-size ","size":"50"},on:{"click":function($event){return _vm.viewCameraDetails(camera.camera_id)}}},[_vm._v(" View ")]):_vm._e()],2)],1),_c(VExpansionPanelContent,[_c(VRow,[_c('u-camera-preview-tile',{staticClass:"my-4 pa-1",attrs:{"altText":camera.camera_name,"imageUrl":camera.camera_preview}}),_vm._l((camera.videos),function(video,vidIndex){return _c(VCol,{key:vidIndex,staticClass:"pa-0",attrs:{"cols":"auto"}},[_c('u-camera-video-tile',{staticClass:"my-5",attrs:{"altText":video.file_name,"date":video.start_date,"imageUrl":video.video_image_url,"time":video.start_time,"videoName":video.file_name,"videoUrl":video.video_url}})],1)})],2)],1)],1)}),(_vm.showAssignPreviewModal)?_c('assign-preview-modal',{attrs:{"selectedCameraId":_vm.selectedCamera.camera_id},on:{"click:close":_vm.closePreviewModal}}):_vm._e(),_c('u-confirmation-modal',{attrs:{"body":("Are you sure you want to delete camera " + _vm.selectedCameraName + "? This change cannot be undone"),"action-name":"delete","title":"Delete Camera?"},on:{"click:confirmAction":_vm.deleteCamera},model:{value:(_vm.showDeleteConfirmationModal),callback:function ($$v) {_vm.showDeleteConfirmationModal=$$v},expression:"showDeleteConfirmationModal"}}),_c('camera-video-viewer',{attrs:{"videos":_vm.cameraVideos},model:{value:(_vm.showCameraVideoViewer),callback:function ($$v) {_vm.showCameraVideoViewer=$$v},expression:"showCameraVideoViewer"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }