import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticClass:"no-scrollbar",attrs:{"max-width":"800px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.selectedVideo ? _vm.selectedVideo.file_name.replace(/\.[^/.]+$/, "") : "Select a Video")+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('div',{staticClass:"video-container"},[(_vm.selectedVideo)?_c('video',{ref:"videoPlayer",staticClass:"w-100",staticStyle:{"width":"100%"},attrs:{"src":_vm.selectedVideo.video_url,"controls":""}}):_c('div',{staticClass:"placeholder"},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-video-off")]),_c('p',[_vm._v("Please select a video to play")])],1)]),_c('u-dropdown-alt',{staticClass:"mb-4 mt-3 dropdown",attrs:{"items":_vm.videos,"item-text":"file_name","placeholder":"Select Video","prepend-inner-icon":"fas fa-video","return-object":""},on:{"change":_vm.onVideoChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VIcon,[_vm._v("fas fa-video")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.file_name))])]}}]),model:{value:(_vm.selectedVideo),callback:function ($$v) {_vm.selectedVideo=$$v},expression:"selectedVideo"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }