import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"ma-2",attrs:{"width":"150"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VSheet,{staticClass:"hoverable-sheet",attrs:{"width":"150","height":"150","color":"gray-2","rounded":"lg"},on:{"click":function($event){_vm.dialog = true}}},[(hover)?_c(VImg,{staticClass:"rounded-lg",attrs:{"width":"150","height":"150","src":_vm.imageUrl,"alt":_vm.altText},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VSheet,{staticClass:"fill-height d-flex align-center justify-center",attrs:{"color":"background","rounded":"lg","max-height":"150"}},[_c(VIcon,{attrs:{"color":"gray-5","x-large":""}},[_vm._v("fas fa-images ")])],1)]},proxy:true}],null,true)}):_c(VSheet,{staticClass:"rounded-lg d-flex flex-column justify-center white--text",attrs:{"width":"150","height":"150","color":"secondary"}},[_c('h4',{staticClass:"text-h4 ma-0 px-2 text-wrap"},[_vm._v(_vm._s(_vm.time))]),_c('p',{staticClass:"body-1 px-2 mb-0"},[_vm._v(_vm._s(_vm.date))])])],1)]}}])}),_c('p',{staticClass:"py-1 ma-0 body-1 text-break video-label"},[_vm._v(" "+_vm._s(_vm.videoName.replace(/\.[^/.]+$/, ""))+" ")]),_c(VDialog,{attrs:{"width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.videoName.replace(/\.[^/.]+$/, ""))+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('video',{staticStyle:{"width":"100%","height":"auto"},attrs:{"controls":"","src":_vm.videoUrl}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }