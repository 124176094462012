<template>
  <v-sheet width="150" class="ma-2">
    <v-hover v-slot:default="{ hover }">
      <v-sheet width="150" height="150" color="gray-2" rounded="lg" @click="dialog = true"
        class="hoverable-sheet">
        <!-- Preview Image -->
        <v-img
          width="150"
          height="150"
          :src="imageUrl"
          :alt="altText"
          class="rounded-lg"
          v-if="hover"
        >
          <!-- Image placeholder-->
          <template v-slot:placeholder>
            <v-sheet
              class="fill-height d-flex align-center justify-center"
              color="background"
              rounded="lg"
              max-height="150"
            >
              <v-icon color="gray-5" x-large>fas fa-images </v-icon>
            </v-sheet>
          </template>
        </v-img>
        <v-sheet
          v-else
          width="150"
          height="150"
          class="rounded-lg d-flex flex-column justify-center white--text"
          color="secondary"
        >
          <!-- Time and Date -->
          <h4 class="text-h4 ma-0 px-2 text-wrap">{{ time }}</h4>
          <p class="body-1 px-2 mb-0">{{ date }}</p>
        </v-sheet>
      </v-sheet>
    </v-hover>
    <!-- Video File Name -->
    <p class="py-1 ma-0 body-1 text-break video-label">
      {{ videoName.replace(/\.[^/.]+$/, "") }}
    </p>

    <!-- Video Player Dialog -->
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title>
          {{ videoName.replace(/\.[^/.]+$/, "") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <video
            controls
            :src="videoUrl"
            style="width: 100%; height: auto;"
          ></video>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
export default {
  name: "UCameraVideoTile",
  props: {
    time: {
      type: String,
      default: "",
      required: true,
    },
    date: {
      type: String,
      default: "",
      required: true,
    },
    imageUrl: {
      type: String,
      default: "",
      required: true,
    },
    altText: {
      type: String,
      default: "",
    },
    videoName: {
      type: String,
      default: "",
      required: true,
    },
    videoUrl: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
<style lang="css" scoped>
.video-label {
  line-height: 110%;
}

.hoverable-sheet {
  cursor: pointer;
}
</style>
