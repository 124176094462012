import Vue from "vue";
import ApiService from "@/services/ApiService";

const getInitialState = () => ({
  activeCamera: undefined,
  configs: {},
  statuses: [],
});

const state = getInitialState();

const getters = {
  // Get Config object for specified camera
  getConfigByCamera: (state) => (id) => {
    return state.configs[id] ?? {};
  },

  selectedDetectionStatus: (state) => {
    return (
      state.statuses?.filter(
        (status) => status.camera_id === state.activeCamera?.camera_id,
      ) ?? []
    );
  },

  getDetectionStatusByCamera: (state) => (camera_id) => {
    return state.statuses?.filter(
      (status) => status.camera_id === camera_id
    ) ?? [];
  },
};

const mutations = {
  // Set Canvas Markers of camera in configs state
  SET_CANVAS_MARKERS(state, { cameraId, markers }) {
    const config = state.configs[cameraId];
    Vue.set(state.configs, cameraId, {
      ...config,
      canvasMarkers: markers,
    });
  },

  // Set Canvas Area of camera in configs state
  SET_CANVAS_AREA(state, { cameraId, area }) {
    const config = state.configs[cameraId];
    Vue.set(state.configs, cameraId, {
      ...config,
      canvasArea: area,
    });
  },

  // Set Map Markers of camera in configs state
  SET_MAP_MARKERS(state, { cameraId, markers }) {
    const config = state.configs[cameraId];
    Vue.set(state.configs, cameraId, { ...config, mapMarkers: markers });
  },

  // Set Active Camera State
  SET_ACTIVE_CAMERA(state, camera) {
    state.activeCamera = camera;
  },

  SET_DETECTION_STATUSES(state, statuses) {
    state.statuses = [...statuses];
  },

  // Reset state to initial state
  RESET_STATE(state) {
    Object.assign(state, getInitialState());
  },
};

const actions = {
  /**
   * Update camera config for selected camera
   * @param state
   * @param commit
   * @param {String} type - Type of Action
   * @param {Array} payload - data to update state with
   */
  updateCurrentConfig({ state, commit }, { type = "", payload }) {
    const cameraId = String(state.activeCamera.camera_id);

    if (!Object.keys(state.configs).includes(cameraId))
      state.configs[cameraId] = {};

    switch (type) {
      case "CANVAS_MARKERS":
        commit("SET_CANVAS_MARKERS", { cameraId, markers: payload });
        break;
      case "CANVAS_AREA":
        commit("SET_CANVAS_AREA", { cameraId, area: payload });
        break;
      case "MAP_MARKERS":
        commit("SET_MAP_MARKERS", { cameraId, markers: payload });
        break;
    }
  },

  async getDetectionStatuses({ rootState, commit, dispatch }) {
    try {
      const response = await ApiService.getDetectionStatuses(
        rootState.Project.activeProject.project_id,
      );
      commit("SET_DETECTION_STATUSES", response.data["detection_status"]);
    } catch (err) {
      console.error(err);
      dispatch(
        "Notification/addNotification",
        { color: "error", text: "Error loading detection statuses" },
        { root: true },
      );
    }
  },

  resetState({ commit }) {
    commit("RESET_STATE");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
