<template>
  <v-dialog v-model="show" max-width="800px" class="no-scrollbar">
    <v-card>
      <v-card-title>
        {{ selectedVideo ? selectedVideo.file_name.replace(/\.[^/.]+$/, "") : "Select a Video" }}
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <!-- Video Player Container -->
        <div class="video-container">
          <video
            ref="videoPlayer"
            v-if="selectedVideo"
            :src="selectedVideo.video_url"
            controls
            class="w-100"
            style="width: 100%;"
          ></video>
          <!-- Placeholder when no video is selected -->
          <div v-else class="placeholder">
            <v-icon large>mdi-video-off</v-icon>
            <p>Please select a video to play</p>
          </div>
        </div>

        <!-- Dropdown to select video -->
        <u-dropdown-alt
          v-model="selectedVideo"
          :items="videos"
          item-text="file_name"
          placeholder="Select Video"
          prepend-inner-icon="fas fa-video"
          return-object
          @change="onVideoChange"
          class="mb-4 mt-3 dropdown"
        >
          <template #item="{ item }">
            <v-icon>fas fa-video</v-icon>
            <span class="ml-2">{{ item.file_name }}</span>
          </template>
        </u-dropdown-alt>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { UDropdownAlt } from "@/components/base";

export default {
  name: "CameraVideoViewer",
  components: {
    UDropdownAlt,
  },
  props: {
    videos: {
      type: Array,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: this.value,
      selectedVideo: null,
    };
  },
  watch: {
    value(value) {
      this.show = value;
    },
    show(value) {
      this.$emit("input", value);
    },
    videos(newVideos) {
      if (newVideos.length === 0) {
        this.selectedVideo = null;
      }
    },
  },
  methods: {
    close() {
      this.show = false;
      this.selectedVideo = null;
      this.$emit("input", false);
    },
    onVideoChange() {

      if (this.selectedVideo) {
        this.$refs.videoPlayer.load();
        this.$refs.videoPlayer.play();
      }
    },
  },
};
</script>

<style scoped>
.ml-2 {
  margin-left: 0.5rem;
}

.w-100 {
  width: 100%;
}

.dropdown {
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 4px; 
  padding: 8px;
}

.video-container {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 8px;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #aaa;
}

.no-scrollbar .v-dialog__content {
  overflow: hidden !important;
}

.no-scrollbar .v-dialog {
  overflow: hidden !important;
}
</style>
