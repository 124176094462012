<template>
  <!-- logo -->
  <u-base-nav :extended="extended">
    <template #logo>
      <u-icon-logo
        class="fill-height nav-logo"
        @click.native="$router.push({ path: '/dashboard' })"
      />
    </template>
    <v-divider class="nav-divider" vertical inset />
    <template>
      <!-- Title -->
      <v-row align="center" class="mx-2 fill-height">
        <v-col cols="3" md="4">
          <h1 class="white--text mx-2 text-truncate">{{ title }}</h1>
        </v-col>
        <v-spacer></v-spacer>
        <!-- navigation Items -->
        <v-card
          v-for="item in navLinks"
          :key="item.text"
          :to="item.to"
          active-class="active-nav-tile"
          class="px-8 nav-tile d-flex align-center fill-height"
          elevation="0"
          tile
        >
          {{ item.text }}
        </v-card>
        <u-top-nav-menu class="mx-1" />
        <v-avatar class="mx-2" color="light" size="32">
          <h2>?</h2>
        </v-avatar>
      </v-row>
    </template>
    <template #extension>
      <slot name="extension" />
    </template>
  </u-base-nav>
</template>

<script>
import { UBaseNav } from "@/components/base";
import { UIconLogo } from "@/components/icons";
import UTopNavMenu from "@/components/common/UTopNavMenu";

export default {
  name: "UProjectTopNav",
  components: { UTopNavMenu, UIconLogo, UBaseNav },
  props: {
    title: {
      type: String,
      default: "",
    },
    extended: Boolean,
  },
  data: () => ({
    navLinks: [
      {
        text: "Setup",
        to: "/setup",
      },
      {
        text: "Config",
        to: "/config",
      },
      {
        text: "Metrics",
        to: "/metrics",
      },
      {
        text: "Analytics",
        to: "/analytics",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
::v-deep div.v-toolbar__content {
  padding: 0;
}

::v-deep div.v-toolbar__extension {
  padding: 0;
}

.nav-logo {
  min-width: 56px;
  max-width: 64px;
}

.border-right {
  border-right: 3px solid var(--v-light-base);
}

.nav-tile {
  color: white;
  background-color: var(--v-secondary-base);
  border-inline: 1px solid var(--v-secondary-base);

  &:hover {
    background-color: var(--v-gray-2-base);
  }
}

.active-nav-tile {
  color: var(--v-secondary-base);
  font-weight: bold;
  background-color: var(--v-primary-base);
}

::v-deep .nav-divider.theme--light.v-divider {
  border-radius: 100vh;
  border-left-width: 3px;
  border-color: #0f4b74;
}
</style>
