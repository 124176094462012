<template>
  <v-expansion-panels flat multiple>
    <v-expansion-panel
      v-for="(camera, index) in sortedCameraVideos"
      :key="camera.camera_id"
    >
      <!-- Panel Header -->
      <v-row align="center" justify="space-between">
        <v-col class="flex-grow-1" cols="auto">
          <v-expansion-panel-header>
            <p class="mb-0 d-flex align-center">
              <camera-icon :number="index + 1" :size="22" />
              <span class="mx-2 subtitle-1 font-weight-bold">
                {{ camera.camera_name }}
              </span>
            </p>
            <template v-slot:actions>
              <v-icon color="secondary" small>mdi-chevron-down</v-icon>
            </template>
          </v-expansion-panel-header>
        </v-col>
        <v-col cols="auto">
          <!-- Action Buttons -->
          <u-button
            class="rounded mr-2"
            color="gray-7"
            fab
            small
            tile
            @click="launchAssignPreviewModal(camera)"
          >
            <v-icon small>fas fa-pen</v-icon>
          </u-button>
          <u-button
            class="rounded mr-2"
            color="gray-7"
            fab
            small
            tile
            @click="launchDeleteConfirmationModal(camera)"
          >
            <v-icon small>fas fa-trash</v-icon>
          </u-button>
        </v-col>
        <v-container class="pb-0 col d-flex justify-space-between status">
          <!-- UStatusIndicator for each camera -->
          <u-status-indicator
            v-for="({ status, timestamp }, idx) in getDetectionStatusByCamera(camera.camera_id)"
            :key="idx"
            :camera-index="camera.camera_id"
            :status="status.toLowerCase()"
            :timestamp="timestamp ? new Date(timestamp).toDateString() : '--'"
          />
          <u-button
            v-if="getLatestStatus(camera.camera_id) === 'processed'"
            color="primary theme--light secondary--text font-weight-bold btn-size "
            @click="viewCameraDetails(camera.camera_id)"
            size="50"
          >
            View
          </u-button>
        </v-container> 
      </v-row>

      <!-- Content (Videos) -->
      <v-expansion-panel-content>
        <v-row>
          <u-camera-preview-tile
            :altText="camera.camera_name"
            :imageUrl="camera.camera_preview"
            class="my-4 pa-1"
          />
          <v-col
            v-for="(video, vidIndex) in camera.videos"
            :key="vidIndex"
            class="pa-0"
            cols="auto"
          >
            <u-camera-video-tile
              :altText="video.file_name"
              :date="video.start_date"
              :imageUrl="video.video_image_url"
              :time="video.start_time"
              :videoName="video.file_name"
              :videoUrl="video.video_url"
              class="my-5"
            />
          </v-col> 
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <!-- Modals -->
    <assign-preview-modal
      v-if="showAssignPreviewModal"
      :selectedCameraId="selectedCamera.camera_id"
      @click:close="closePreviewModal"
    />
    <u-confirmation-modal
      v-model="showDeleteConfirmationModal"
      :body="`Are you sure you want to delete camera ${selectedCameraName}? This change cannot be undone`"
      action-name="delete"
      title="Delete Camera?"
      @click:confirmAction="deleteCamera"
    />

    <!-- Camera Video Viewer Modal -->
    <camera-video-viewer
      v-model="showCameraVideoViewer"
      :videos="cameraVideos"
    />
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from "vuex";
import { UButton } from "@/components/base";
import { CameraIcon } from "@/components/icons";
import { UCameraPreviewTile, UCameraVideoTile, UStatusIndicator } from "@/components/common";
import { AssignPreviewModal, UConfirmationModal } from "@/components/modals";
import CameraVideoViewer from "@/components/modals/CameraVideoViewer.vue";
import ApiService from "@/services/ApiService";

export default {
  name: "VideoLibraryCameraVideosList",
  components: {
    UConfirmationModal,
    CameraIcon,
    UCameraPreviewTile,
    UCameraVideoTile,
    UButton,
    AssignPreviewModal,
    UStatusIndicator,
    CameraVideoViewer,
  },
  props: {
    cameras: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    ...mapGetters("CameraConfig", [
      "getConfigByCamera",
      // Removed 'selectedDetectionStatus'
    ]),

    // Computed property to get detection statuses by camera_id
    getDetectionStatusByCamera() {
      return (camera_id) => {
        return this.$store.getters["CameraConfig/getDetectionStatusByCamera"](camera_id);
      };
    },

    selectedCameraName() {
      return this.selectedCamera?.camera_name ?? "";
    },

    // Sort videos of all cameras by video_start_time
    sortedCameraVideos() {
      const sortedVideosCameras = [...this.cameras];
      sortedVideosCameras.forEach((camera) => {
        camera.videos.sort((x, y) => {
          return new Date(x.video_start_time) - new Date(y.video_start_time);
        });
      });
      return sortedVideosCameras;
    },
  },
  data: () => ({
    selectedCamera: undefined,
    showAssignPreviewModal: false,
    showDeleteConfirmationModal: false,
    cameraVideos: [],
    showCameraVideoViewer: false,
  }),
  methods: {
    async deleteCamera() {
      if (this.selectedCamera) {
        try {
          await this.$store.dispatch("Project/deleteCamera", this.selectedCamera);
          this.showDeleteConfirmationModal = false;
          this.selectedCamera = undefined;
          
        } catch (error) {
          console.error("Error deleting camera:", error);
        }
      }
    },

    /**
     * Launches the assign preview modal
     * @param {Object} camera - Selected camera
     */
    launchAssignPreviewModal(camera) {
      this.selectedCamera = camera;
      this.showAssignPreviewModal = true;
    },

    /**
     * Launches the delete confirmation modal
     * @param {Object} camera - Selected camera
     */
    launchDeleteConfirmationModal(camera) {
      this.selectedCamera = camera;
      this.showDeleteConfirmationModal = true;
    },

    closePreviewModal() {
      this.selectedCamera = undefined;
      this.showAssignPreviewModal = false;
    },

    getLatestStatus(camera_id) {
      const statuses = this.getDetectionStatusByCamera(camera_id);
      if (!statuses || statuses.length === 0) return null;

      // Sort statuses by timestamp in descending order (latest first)
      const sortedStatuses = [...statuses].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );

      // Return the latest status in lowercase
      return sortedStatuses[0].status.toLowerCase();
    },

    async viewCameraDetails(cameraId) {

      try {
        const res = await ApiService.getProcessedVideo(cameraId);
        this.cameraVideos = res.data;
        this.showCameraVideoViewer = true;
        
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.$store.dispatch("CameraConfig/getDetectionStatuses");
  },
};
</script>

<style scoped>
.status{
 /* border: 1px solid red; */
 min-width: 45%;
 /* max-height: 100px; */
 align-items: center;
}
.btn-size{
 width: 120px;
 height: 100%;
 box-shadow: inset -4.62438px -4.62438px 11.561px rgba(255, 255, 255, 0.3),
    inset 3.46829px 3.46829px 4.62438px rgba(3, 23, 39, 0.05);
}
</style>